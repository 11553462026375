<template>
  <div>
    <v-app-bar class="pr-5" color="#FFFFFF" app>
      <v-app-bar-nav-icon class="d-flex d-sm-none" @click="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-toolbar-title class="mainToolbar-title">
        {{ barTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="#F2F2F2"
        class="switchCampaignBtn mr-5"
        @click="toggleSelectGameModal({ show: true })"
        v-if="gamedtail.same_game_count > 1"
      >
        <v-icon color="#6B6B6B" size="20" left>mdi-repeat</v-icon>
        <span class="mr-2" style="color: #383838">Switch Games</span>
        <v-icon color="#6B6B6B" size="25" right>mdi-chevron-down</v-icon>
      </v-btn>

      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="rgba(255, 81, 81, 0.12)" size="38">
              <span class="user-initials-text">{{ user.initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="rgba(255, 81, 81, 0.12)">
                <span class="user-initials-text">{{ user.initials }}</span>
              </v-avatar>
              <h3>{{ user.user_name }}</h3>
              <p class="text-caption mt-1">
                {{ user.user_email }}
              </p>
              <!-- <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text> Edit Account </v-btn> -->
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded @click="logoutClickHandler" text>
                Logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!--desktop view nav drawer-->
    <v-navigation-drawer
      app
      dark
      color="#2C1963"
      width="245px"
      height="100%"
      permanent
      hide-overlay
      style="box-shadow: 9px 4px 8px rgba(0, 0, 0, 0.08)"
      class="d-none d-sm-flex"
    >
      <div
        style="
          text-align: -moz-center;
          text-align: -webkit-center;
          background: #ffffff;
          min-height: 250px;
          padding-top: 10px;
          text-align: center;
        "
      >
        <img
          src="@/assets/thingsToDo/harlemlogo.png"
          width="80px"
          height="80px"
        />
        <br />
        <strong style="font-size: 22px; color: #38227a"
          >{{ this.gamedtail.city }}, {{ this.gamedtail.state_name }}</strong
        >
        <br />
        <strong style="font-size: 18px; color: #38227a">{{
          this.gamedtail.organization
        }}</strong>
        <br />
        <strong style="font-size: 16px; color: #38227a">{{
          this.gamedtail.game_date_str
        }}</strong>
        <br />
        <strong style="font-size: 16px; color: gray">{{
          this.gamedtail.start_time_str
        }}</strong>
        <br/>
        <strong style="font-size: 16px; color: #38227a">{{
          this.gamedtail.game_type
        }}</strong>
        <br>
           <strong style="font-size: 16px; color: #38227a">{{
          this.gamedtail.team_name
        }}</strong>
      </div>
      <v-list class="mt-2 pa-0" nav dense>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            class="justify-center"
            active-class="active-class"
            v-for="(item, i) in navLinks"
            :key="i"
            @click="ChangeTab(item.heading, item.select)"
            :to="{ name: item.to, query: queryParams }"
            link
          >
            <template v-slot:default="{ active }">
              <div style="width: 70%">
                <img
                  style="max-width: 15px; margin-bottom: -2px"
                  class="display-inline"
                  :src="
                    getImgUrl(
                      active ? `${item.icon}Active.svg` : `${item.icon}.svg`
                    )
                  "
                />
                <v-list-item-title
                  class="flex-zero display-inline ml-6 font-roboto-slab"
                >
                  {{ item.title }}
                </v-list-item-title>
              </div>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!--mobile view nav drawer-->
    <v-navigation-drawer
      app
      v-model="drawer"
      dark
      color="#2C1963"
      width="290px"
      height="100%"
      temporary
      hide-overlay
      style="box-shadow: 9px 4px 8px rgba(0, 0, 0, 0.08)"
      class="d-flex d-sm-none"
    >
      <div v-if="loader">
        <v-sheet color="grey darken-2 lighten-4" class="pa-3">
          <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
      </div>
      <div
        class="pt-6"
        style="
          text-align: -moz-center;
          text-align: -webkit-center;
          background: #ffffff;
          min-height: 250px;
        "
        v-else
      >
         <img
          src="@/assets/thingsToDo/harlemlogo.png"
          width="80px"
          height="80px"
        />
        <br />
        <strong style="font-size: 22px; color: #38227a"
          >{{ this.gamedtail.city }}, {{ this.gamedtail.state_name }}</strong
        >
        <br />
        <strong style="font-size: 18px; color: #38227a">{{
          this.gamedtail.organization
        }}</strong>
        <br />
        <strong style="font-size: 16px; color: #38227a">{{
          this.gamedtail.game_date_str
        }}</strong>
        <br />
        <strong style="font-size: 16px; color: gray">{{
          this.gamedtail.start_time_str
        }}</strong>
        <br/>
        <strong style="font-size: 16px; color: #38227a">{{
          this.gamedtail.game_type
        }}</strong>
        <br>
           <strong style="font-size: 16px; color: #38227a">{{
          this.gamedtail.team_name
        }}</strong>
       
      </div>
      <v-list class="pa-0" nav dense>
        <v-list-item-group>
          <v-list-item
            class="justify-center"
            active-class="active-class"
            v-for="(item, i) in navLinks"
            :key="i"
            @click="ChangeTab(item.heading, item.select)"
            :to="{ name: item.to, query: queryParams }"
            link
            exact
          >
            <template v-slot:default="{ active }">
              <div style="width: 70%">
                <img
                  max-width="19"
                  class="mb-n1 display-inline"
                  :src="
                    getImgUrl(
                      active ? `${item.icon}Active.svg` : `${item.icon}.svg`
                    )
                  "
                />
                <v-list-item-title
                  class="flex-zero display-inline ml-10 font-roboto-slab"
                  >{{ item.title }}</v-list-item-title
                >
              </div>
            </template>
          </v-list-item>
          <!-- <v-list-item class="d-sm-none">
            <v-icon>mdi-logout</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="height: auto; background: #f7f7f7">
      <router-view></router-view>
    </v-main>
    <select-game-modal @reload="loadData(),relaodPage" ></select-game-modal>
    <game-modal></game-modal>
  </div>
</template>
<script>
//import {GAME} from "@/constants/ModuleKeys";
import { ADMIN_GAMES_GET_DETAIL } from "@/constants/APIUrls";
import authToken from "@/common/authToken";
import { ROUTER_URL } from "@/constants/urls";
import { mapActions, mapGetters } from "vuex";
import Helper from "@/helper";
import Axios from "@/api/BaseAxios";
import { GAME_ID } from "@/constants/APIKeys";
export default {
  name: "gameLayout",
  components: {
    SelectGameModal: () =>
      import("@/views/GameManagement/SelectGameModal/SelectGameModal"),
    GameModal: () =>
      import("@/views/GameManagement/AddEditGameModal/AddEditGameModal"),
  },
  data() {
    return {
      helper: Helper,
      user: {},
      gamedtail: {},
      barTitle: "Game Dashboard",
      navLinks: [
        {
          title: "Dashboard",
          icon: "dashboard",
          heading: "Game Dashboard",
          key: "dashboard",
          to: ROUTER_URL.gamePanel.children.dashboard.name,
        },
        {
          title: "Game Resources",
          icon: "sponsor",
          heading: "Game Resource Management",
          key: "gameresource",
         to: ROUTER_URL.gamePanel.children.gameresource.name,
        },
          {
          title: "Calendar",
          icon: "calendars",
          heading: "Calendar Management",
          key: "gamecalendar",
          to: ROUTER_URL.gamePanel.children.gamecalendar.name,
        },
          {
          title: "Host Portal",
          icon: "calendars",
          heading: "Game Host Assembly Management",
          key: "gamehostassembly",
          to: ROUTER_URL.gamePanel.children.gamehostassembly.name,
        },
          {
          title: "Host Committee",
          icon: "team",
          heading: "Game Host Committee Management",
          key: "gamecommittee",
          to: ROUTER_URL.gamePanel.children.gamecommittee.name,
        },
          /**  {
          title: "Emails",
          icon: "email",
          heading: "Game Emails Management",
          key: "gameemail",
          to: ROUTER_URL.gamePanel.children.gameemail.name,
        },  */
      /**    {
          title: "Ticket Updates",
          icon: "donation",
          heading: "Game Ticket Updates Management",
          key: "gameticketupdate",
          to: ROUTER_URL.gamePanel.children.gameticketupdate.name,
        }, */
        // {
        //     title:"Marketing",
        //     icon:"dashboard",
        //     heading:"Marketing Resources",
        //     key:"marketingResources",
        //     to:"mak"
        // }
      ],
      drawer: false,
      loader: true,
      selected: "Dashboard",
      selectedItem: 0,
    };
  },
  created() {
    this.$root.$refs.gameLayout = this;
  },
  computed: {
    ...mapGetters({
      selectGameData: "gameManagement/getGame",
    }),
    currentRouteName() {
      return this.$route.name;
    },
    queryParams() {
   
      let item = {};
      let USER_PANEL = this.$route.matched[0].path.substring(1);
      if (this.$route.query[GAME_ID])
        item[GAME_ID] = this.$route.query[GAME_ID];
      if (this.$route.query[USER_PANEL])
        item[GAME_ID] = this.$route.query[GAME_ID];
      //  if (this.$route.matched[0].path.substring(1) === "game")
        //console.log("game",this.selectGameData)
        // alert(this.selectGameData.organization.id)
      //  item[GAME] = this.$route.query["game"];
      return item;
    },
  },
 watch: {
    currentRouteName(val) {
     if (val === ROUTER_URL.gamePanel.children.dashboard.name) {
    location.reload()
    }
      if (val === ROUTER_URL.gamePanel.children.dashboard.name) {
        this.barTitle = "dashboard";
      }
       if (val === ROUTER_URL.gamePanel.children.gamecalendar.name) {
         location.reload()
      }
       if (val === ROUTER_URL.gamePanel.children.gamehostassembly.name) {
         location.reload()
      }
       if (val === ROUTER_URL.gamePanel.children.gamecommittee.name) {
         location.reload()
      }
       if (val === ROUTER_URL.gamePanel.children.gameticketupdate.name) {
         location.reload()
      }
    },
  },
  methods: {
    ...mapActions({
      toggleSelectGameModal: "gameManagement/toggleSelectModal",
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
    }),
    logoutClickHandler() {
      authToken.removeAccessToken();
      this.$router.push({ name: ROUTER_URL.auth.children.login.name });
    },
     relaodPage(){
      this.$router.go()
    },
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    getImgUrl(img) {
      return require("@/assets/Navigation/" + img);
    },
    ChangeTab(heading, select) {
      this.barTitle = heading;
      this.drawer = false;
      this.selected = select;
    },
    loadData() {
      console.log("loading data");
      console.log(this.selectGameData);
      //  this.toggleAddEditViewGameModal({show:true,type:'edit',dashboard:true,id:this.$route.query.game_id})
      this.loader = false;
    },
    getGameDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.gamedtail = res.data.game_detail;
        this.goldMineId=this.gamedtail.goldmine_id;
        localStorage.setItem("goldmineid",this.goldMineId)
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      localStorage.setItem("gameID",this.$route.query.game_id)
      Axios.request_GET(
        ADMIN_GAMES_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
       setupNavLinksForGame() {
      let removeLinks = ["dashboard","gameresource","gamecalendar"];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
       setupNavLinksForEventSalesRep() {
      let removeLinks = ["gamecommittee","gameticketupdate"];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
  },
  mounted() {
    this.getGameDetail();
    this.user = { ...authToken.decodedToken() };
    let initials = this.getInitials(this.user.user_name);
    this.user = { ...this.user, initials };
     this.selectedItem = this.navLinks.findIndex(
      (item) => item.key === this.currentRouteName
    );
   // alert(this.user.user_type)
   // this.toggleSelectGameModal({ show: true });
      if (this.user.user_type === 'game')
      this.setupNavLinksForGame();
        if (this.user.user_type === 'super admin'||this.user.user_type === 'event support staff'||this.user.user_type ==='sales representative')
      this.setupNavLinksForEventSalesRep();
  },
};
</script>
<style scoped>
.switchCampaignBtn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.switchCampaignBtn >>> span {
  font-family: Lato;
  letter-spacing: 0px;
  text-transform: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 315%;
}
.mainToolbar-title {
  font-family: LubalGraph Bd BT;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 315%;
  color: #2d2d2d;
  white-space: nowrap;
}
.campaignStatus >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 161.5%;
}

.ongoing {
  background: #fcede0 !important;
}
.ongoing >>> span {
  color: #e76f00 !important;
}
.upcoming {
  background-color: rgba(0, 133, 255, 0.12) !important;
}
.upcoming >>> span {
  color: #0085ff !important;
}
.completed {
  background-color: rgba(6, 118, 5, 0.12) !important;
}
.completed >>> span {
  color: #067605 !important;
}
.campaignDates {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #1d1d1d;
  margin-top: 5px;
}
.campaignId {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  color: rgba(29, 29, 29, 0.6);
}
.flex-zero {
  flex: none;
}
.display-inline {
  display: inline;
}
.font-roboto-slab {
  font-family: Roboto Slab !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 300% !important;
  color: #9387b6 !important;
}
.active-class {
  border-right: 6px solid #ffba00;
  width: 100%;
  border-radius: 0px !important;
}
.active-class >>> .v-list-item__title {
  color: #ffffff !important;
}
* >>> .theme--dark.v-list-item--active::before {
  opacity: 0.14;
  border-radius: 0px;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}
.orgLogo-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}

.tabs {
  margin-left: 100px;
}
.schoolName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 127%;
  color: #2c1963;
  cursor: pointer;
}
span.v-chip.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
}
span.v-chip.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
}
span.v-chip.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
}
@media (min-width: 0px) and (max-width: 600px) {
  .tabs {
    margin-left: 0px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>

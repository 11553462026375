<template>
  <div>
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 50px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>
    <div v-else class="landingPageDiv">
      <v-container>
        <v-row justify="center" class="pt-10">
          <v-col cols="2">
            <img
              src="@/assets/schoolLandingPage/wizfitLogo.svg"
              width="150px"
              alt=""
            />
          </v-col>
          <v-col cols="9" class="pt-12">
            <div class="pageHeading">Welcome to Harlem Wizards in a Box.</div>
          </v-col>
        </v-row>
        <v-row justify="center" class="pt-10">
          <v-col cols="5">
            <v-card @click="route(true)">
              <v-card-text style="background: #fdd5cd; min-height: 580px" >
                <img
                  width="450px"
                  src="@/assets/schoolLandingPage/campaignDashboardImage.png"
                  alt=""
                />
              </v-card-text>
              <v-card-actions
                style="background: #ee1f51; min-height: 80px"
                class="justify-center"
              >
                <div
                  class="buttonText"
                  style="color: white"
                  @click="route(true)"
                >
                  Go to WizFit Campaign Dashboard
                </div>
              </v-card-actions>
            </v-card>
            <div class="text-center" style="width: 100%">
              <p class="textProfit">
                Profit this year = ${{ campaignDetail.current_profit }}
              </p>
              <div class="d-flex justify-center">
                <div
                  :class="
                    profitCampaign
                      ? 'profitCompareDiv profitColorBackground'
                      : 'profitCompareDiv lossColorBackground'
                  "
                >
                  <v-icon v-if="profitCampaign" color="white"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon v-else color="white">mdi-arrow-down</v-icon>
                  <div>
                    {{ campaignDetail.compare_profit }}% vs previous campaign
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-divider vertical style="background: #ececec"></v-divider>
          </v-col>
          <v-col cols="5">
            <v-card @click="route(false)">
              <v-card-text style="background: #fdd5cd; min-height: 580px">
                <img
                  src="@/assets/schoolLandingPage/gameDashboardImage.png"
                  width="450px"
                  alt=""
                />
              </v-card-text>
              <v-card-actions
                style="background: #ffc300; min-height: 80px"
                class="justify-center"
              >
                <div
                  class="buttonText"
                  style="color: #2c1963"
                  @click="route(false)"
                >
                  Go to Game Dashboard
                </div>
              </v-card-actions>
            </v-card>
            <div class="text-center" style="width: 100%">
              <p class="textProfit">
                Profit this year = ${{ gameDetail.current_profit }}
              </p>
              <div class="d-flex justify-center">
                <div
                  :class="
                    profitGame
                      ? 'profitCompareDiv profitColorBackground'
                      : 'profitCompareDiv lossColorBackground'
                  "
                >
                  <v-icon v-if="profitGame" color="white">mdi-arrow-up</v-icon>
                  <v-icon v-else color="white">mdi-arrow-down</v-icon>
                  <div>
                    {{ gameDetail.compare_profit }}% vs previous campaign
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!--<div class="backgroundImageDiv">
        <img
          class="backgroundImage"
          src="@/assets/schoolLandingPage/rac.svg"
          alt=""
        />
        <v-spacer></v-spacer>
        <img
          class="backgroundImage"
          src="@/assets/schoolLandingPage/circle.svg"
          alt=""
        />
      </div>-->
    </div>
  </div>
</template>
<script>
import { SCHOOL_DASHBOARD_CHECK_API } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "SchoolLandingPage",
  data() {
    return {
      loading: true,
      campaignDetail: {},
      gameDetail: {},
      profitCampaign: false,
      profitGame: true,
      progressStatus:"",
    };
  },
  computed: {},
  watch: {},
  methods: {
    getDashboardDetail() {
      const successHandler = (res) => {
        this.campaignDetail = res.data.campaign_detail;
        this.gameDetail = res.data.game_detail;
        this.progressStatus=res.data.progress;
        localStorage.setItem("progressValue",this.progressStatus)
        this.checkTheCondition();
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      Axios.request_GET(
        SCHOOL_DASHBOARD_CHECK_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    checkTheCondition() {
      console.log(this.campaignDetail, this.gameDetail);
      if (
        this.campaignDetail.total_campaign > 0 &&
        this.gameDetail.total_game > 0
      ) {
        this.loading = false;
      } else if (
        this.campaignDetail.total_campaign > 0 &&
        this.gameDetail.total_game === 0 && this.progressStatus=='complete'
      ) {
        this.$router.push(
          ROUTER_URL.schoolPanel.link +
            ROUTER_URL.schoolPanel.children.dashboard.link
        );
      } 
      else if (
        this.campaignDetail.total_campaign > 0 &&
        this.gameDetail.total_game === 0 && this.progressStatus=='pending'
      ) {
        this.$router.push(
          ROUTER_URL.schoolPanel.link +
            ROUTER_URL.schoolPanel.children.hosttodolist.link
        );
      } else {
        this.$router.push(
          ROUTER_URL.schoolPanel.link +
            ROUTER_URL.schoolPanel.children.dashboard.link
        );
      }
    },
    route(status) {
      if (status) {
        this.$router.push(
          ROUTER_URL.schoolPanel.link +
            ROUTER_URL.schoolPanel.children.dashboard.link
        );
      } else {
        this.$router.push(
          ROUTER_URL.gamePanel.link +
            ROUTER_URL.gamePanel.children.dashboard.link
        );
      }
    },
  },
  mounted() {
    this.getDashboardDetail();
  },
};
</script>
<style scoped>
.landingPageDiv {
  background: #2c1963;
  min-height: 100vh;
}
.backgroundImageDiv {
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
}
.backgroundImage {
  width: 350px;
}

.pageHeading {
  color: white;
  font-size: 40px;
  font-family: LubalGraph Bd BT;
}
.buttonText {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 700;
  cursor: pointer;
}
.textProfit {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 100.5%;
  /* identical to box height, or 52px */

  color: #ffffff;
  margin-top: 20px;
}
.profitCompareDiv {
  color: #ffffff;
  display: flex;
  padding: 6px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.profitColorBackground {
  background: #38a737;
}
.lossColorBackground {
  background: #ee1f51;
}
</style>
